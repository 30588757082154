var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{attrs:{"dense":"","color":"backgroundAccent","elevation":"0"}},[_c('span',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MTeamTrends view title"}},[_vm._v(" Status per team ")])]),_c('v-container',{staticClass:"d-flex",class:{
      'flex-column': _vm.$vuetify.breakpoint.smAndDown,

    },attrs:{"fluid":""}},[_c('div',{staticClass:"mr-6"},[_c('div',[_c('v-subheader',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MTeamTrends view subtitle"}},[_vm._v(" Teams ")]),_c('v-list',{staticClass:"elevation-2",attrs:{"dense":""}},[(_vm.$wait.is('loading trends'))?_c('m-loader-circular',{staticClass:"pa-12 mx-6",attrs:{"column":"","justify-center":"","align-center":"","color":"primary","text":"","size":20,"width":2}}):_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.selectedTeams),callback:function ($$v) {_vm.selectedTeams=$$v},expression:"selectedTeams"}},_vm._l((_vm.allTeams),function(team){return _c('v-list-item',{key:team.teamId,attrs:{"value":team},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var active = ref.active;
    var toggle = ref.toggle;
return [_c('v-list-item-action',{staticClass:"my-0 mr-2"},[_c('v-checkbox',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return toggle.apply(null, arguments)}},model:{value:(active),callback:function ($$v) {active=$$v},expression:"active"}})],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(team.teamName)+" ")])],1)]}}],null,true)})}),1)],1)],1),_c('div',{staticClass:"mt-4"},[_c('v-subheader',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"MTeamTrends view subtitle"}},[_vm._v(" Filters ")]),_c('v-list',{staticClass:"elevation-2",attrs:{"dense":""}},_vm._l((_vm.filters),function(filter){return _c('div',{key:filter.title},[_c('v-subheader',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(filter.title)+" ")]),_c('v-list-item-group',{attrs:{"multiple":""},on:{"change":_vm.onDatasetChange},model:{value:(_vm.selectedDatasetsIds),callback:function ($$v) {_vm.selectedDatasetsIds=$$v},expression:"selectedDatasetsIds"}},_vm._l((filter.options),function(option){return _c('v-list-item',{key:option.id,attrs:{"value":option.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var active = ref.active;
    var toggle = ref.toggle;
return [_c('v-list-item-action',{staticClass:"my-0 mr-2"},[_c('v-checkbox',{attrs:{"color":"primary"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return toggle.apply(null, arguments)}},model:{value:(active),callback:function ($$v) {active=$$v},expression:"active"}})],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(option.label)+" ")])],1)]}}],null,true)})}),1),(filter !== _vm.filters[_vm.filters.length - 1])?_c('v-divider',{staticClass:"mt-4 mb-2"}):_vm._e()],1)}),0)],1)]),_c('div',{staticClass:"flex-grow-1"},[(_vm.$wait.is('loading trends'))?_c('v-skeleton-loader',{staticClass:"mx-auto pb-4 d-flex flex-wrap justify-center align-center",attrs:{"type":"chart, chart, chart, chart, chart, chart","tile":"","types":{
          chart: 'image',
        }}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$wait.is('loading trends')),expression:"!$wait.is('loading trends')"}],staticClass:"pt-12 masonry-container"},[_c('div',{staticClass:"masonry-col"}),(_vm.teams.length > 1)?_c('div',{staticClass:"masonry-col"}):_vm._e(),(_vm.teams.length > 1)?_c('div',{staticClass:"masonry-col"}):_vm._e(),_vm._l((_vm.teams),function(team,i){return _c('v-card',{key:i,staticClass:"masonry-item"},[_c('v-card-title',{staticClass:"overline"},[_vm._v(" "+_vm._s(team.teamName)+" ")]),_c('v-card-text',[_c('canvas',{ref:("team-chart-" + (team.teamId)),refInFor:true,staticStyle:{"max-width":"100%"}})])],1)})],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }