<template>
  <div>
    <v-app-bar
      dense
      color="backgroundAccent"
      elevation="0"
    >
      <span
        v-translate
        translate-context="MTeamTrends view title"
      >
        Status per team
      </span>
    </v-app-bar>

    <v-container
      fluid
      class="d-flex"
      :class="{
        'flex-column': $vuetify.breakpoint.smAndDown,

      }"
    >
      <div
        class="mr-6"
      >
        <div>
          <v-subheader
            v-translate
            translate-context="MTeamTrends view subtitle"
          >
            Teams
          </v-subheader>

          <v-list
            dense
            class="elevation-2"
          >
            <m-loader-circular
              v-if="$wait.is('loading trends')"
              column
              justify-center
              align-center
              class="pa-12 mx-6"
              color="primary"
              text=""
              :size="20"
              :width="2"
            />

            <v-list-item-group
              v-else
              v-model="selectedTeams"
              multiple
            >
              <v-list-item
                v-for="team in allTeams"
                :key="team.teamId"
                :value="team"
              >
                <template v-slot:default="{ active, toggle }">
                  <v-list-item-action
                    class="my-0 mr-2"
                  >
                    <v-checkbox
                      v-model="active"
                      color="primary"
                      @click.stop.prevent="toggle"
                    />
                  </v-list-item-action>

                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ team.teamName }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>

        <div
          class="mt-4"
        >
          <v-subheader
            v-translate
            translate-context="MTeamTrends view subtitle"
          >
            Filters
          </v-subheader>

          <v-list
            dense
            class="elevation-2"
          >
            <div
              v-for="filter in filters"
              :key="filter.title"
            >
              <v-subheader class="ml-3">
                {{ filter.title }}
              </v-subheader>

              <v-list-item-group
                v-model="selectedDatasetsIds"
                multiple
                @change="onDatasetChange"
              >
                <v-list-item
                  v-for="option in filter.options"
                  :key="option.id"
                  :value="option.id"
                >
                  <template v-slot:default="{ active, toggle }">
                    <v-list-item-action
                      class="my-0 mr-2"
                    >
                      <v-checkbox
                        v-model="active"
                        color="primary"
                        @click.stop.prevent="toggle"
                      />
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-subtitle>
                        {{ option.label }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>

              <v-divider
                v-if="filter !== filters[filters.length - 1]"
                class="mt-4 mb-2"
              />
            </div>
          </v-list>
        </div>
      </div>

      <div
        class="flex-grow-1"
      >
        <v-skeleton-loader
          v-if="$wait.is('loading trends')"
          class="mx-auto pb-4 d-flex flex-wrap justify-center align-center"
          type="chart, chart, chart, chart, chart, chart"
          tile
          :types="{
            chart: 'image',
          }"
        />

        <div
          v-show="!$wait.is('loading trends')"
          class="pt-12 masonry-container"
        >
          <div class="masonry-col" />
          <div
            v-if="teams.length > 1"
            class="masonry-col"
          />
          <div
            v-if="teams.length > 1"
            class="masonry-col"
          />

          <v-card
            v-for="(team, i) in teams"
            :key="i"
            class="masonry-item"
          >
            <v-card-title
              class="overline"
            >
              {{ team.teamName }}
            </v-card-title>

            <v-card-text>
              <canvas
                :ref="`team-chart-${team.teamId}`"
                style="max-width: 100%;"
              />
            </v-card-text>
          </v-card>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { mapWaitingActions } from 'vue-wait';
  import Colcade from 'colcade';
  import Chart from 'chart.js/auto';
  import { tour } from '@/mixins/tour';

  export default {
    mixins: [
      tour,
    ],

    data: () => ({
      masonry: null,
      currentUnit: 'h',
      selectedTeams: [],
      selectedDatasetsIds: [],
      charts: [],
    }),

    computed: {
      ...mapGetters({
        teamsPerformance: 'project/metrics/teamsPerformance',
        project: 'project/project/project',
      }),

      allTeams() {
        return this.teamsPerformance.teams;
      },

      teams() {
        if (this.selectedTeams.length > 0) {
          return this.selectedTeams;
        }

        return this.allTeams;
      },

      meta() {
        return this.teamsPerformance.meta;
      },

      filters() {
        return [
          {
            title: this.$pgettext('MTeamTrends view chart filter', 'Overall status'),
            options: [
              this.datasetOptions[0],
              this.datasetOptions[1],
            ],
          },
          {
            title: this.$pgettext('MTeamTrends view chart filter', 'Overdue'),
            options: [
              this.datasetOptions[2],
              this.datasetOptions[3],
              this.datasetOptions[4],
            ],
          },
          {
            title: this.$pgettext('MTeamTrends view chart filter', 'Interruptions'),
            options: [
              this.datasetOptions[5],
            ],
          },
          {
            title: this.$pgettext('MTeamTrends view chart filter', 'Ahead'),
            options: [
              this.datasetOptions[6],
              this.datasetOptions[7],
              this.datasetOptions[8],
            ],
          },
        ];
      },

      datasetLabels() {
        return {
          cumulativePlanned: this.$pgettext('MTeamTrends view chart label', 'Planned'),
          cumulativeCompleted: this.$pgettext('MTeamTrends view chart label', 'Completed'),
          cumulativeDues: this.$pgettext('MTeamTrends view chart label', 'Overdue'),
          cumulativeNotStarted: this.$pgettext('MTeamTrends view chart label', 'Overdue, not started'),
          cumulativeStarted: this.$pgettext('MTeamTrends view chart label', 'Overdue, started'),
          cumulativeInterruptedTotal: this.$pgettext('MTeamTrends view chart label', 'Interrupted'),
          cumulativeAhead: this.$pgettext('MTeamTrends view chart label', 'Ahead'),
          cumulativeStartedAhead: this.$pgettext('MTeamTrends view chart label', 'Ahead, started'),
          cumulativeCompletedAhead: this.$pgettext('MTeamTrends view chart label', 'Ahead, completed'),
        };
      },

      datasetOptions() {
        return [
          {
            id: 'cumulativePlanned',
            label: this.datasetLabels.cumulativePlanned,
            chartOptions: {
              backgroundColor: 'rgba(0, 150, 136, 0.25)',
              borderColor: 'rgba(0, 150, 136, 0.5)',
              fill: false,
            },
          },
          {
            id: 'cumulativeCompleted',
            label: this.datasetLabels.cumulativeCompleted,
            chartOptions: {
              backgroundColor: 'rgba(0, 0, 255, 0.25)',
              borderColor: 'rgba(0, 0, 255, 0.5)',
              fill: false,
            },
          },
          {
            id: 'cumulativeDues',
            label: this.datasetLabels.cumulativeDues,
            chartOptions: {
              backgroundColor: 'rgba(255, 87, 34, 0.25)',
              borderColor: 'rgba(255, 87, 34, 0.5)',
              fill: false,
            },
          },
          {
            id: 'cumulativeNotStarted',
            label: this.datasetLabels.cumulativeNotStarted,
            chartOptions: {
              backgroundColor: 'rgba(255, 87, 34, 0.25)',
              borderColor: 'rgba(255, 87, 34, 0.5)',
              fill: false,
            },
          },
          {
            id: 'cumulativeStarted',
            label: this.datasetLabels.cumulativeStarted,
            chartOptions: {
              backgroundColor: 'rgba(255, 193, 7, 0.25)',
              borderColor: 'rgba(255, 193, 7, 0.5)',
              fill: false,
            },
          },
          {
            id: 'cumulativeInterruptedTotal',
            label: this.datasetLabels.cumulativeInterruptedTotal,
            chartOptions: {
              backgroundColor: 'rgba(96, 125, 139, 0.25)',
              borderColor: 'rgba(96, 125, 139, 0.5)',
              fill: false,
            },
          },
          {
            id: 'cumulativeAhead',
            label: this.datasetLabels.cumulativeAhead,
            chartOptions: {
              backgroundColor: 'rgba(156, 39, 176, 0.25)',
              borderColor: 'rgba(156, 39, 176, 0.5)',
              fill: false,
            },
          },
          {
            id: 'cumulativeStartedAhead',
            label: this.datasetLabels.cumulativeStartedAhead,
            chartOptions: {
              backgroundColor: 'rgba(156, 39, 176, 0.25)',
              borderColor: 'rgba(156, 39, 176, 0.5)',
              fill: false,
            },
          },
          {
            id: 'cumulativeCompletedAhead',
            label: this.datasetLabels.cumulativeCompletedAhead,
            chartOptions: {
              backgroundColor: 'rgba(3, 169, 244, 0.25)',
              borderColor: 'rgba(3, 169, 244, 0.5)',
              fill: false,
            },
          },
        ];
      },

      chartOptions() {
        return {
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                title: context => `T ${parseInt(context[0].label, 10)}`,
                label: ({ dataset, formattedValue }) => `${dataset.label}: ${formattedValue} ${this.currentUnit}`,
              },
            },
          },
          elements: {
            point: {
              radius: this.teams.length > 1 ? 1 : 3,
            },
          },
          scales: {
            x: {
              ticks: {
                maxTicksLimit: 10,
                callback: (value) => {
                  if (value <= 0) return '';
                  return this.$gettextInterpolate(
                    this.$pgettext('MTeamTrends view chart x axis label', 'T%{ taktNumber }'),
                    { taktNumber: value },
                  );
                },
              },
            },
            y: {
              ticks: {
                maxTicksLimit: 10,
                callback: (v) => {
                  if (v <= 0) return '';
                  return this.$gettextInterpolate(
                    this.$pgettext('MTeamTrends view chart y axis label', '%{ hoursValue } h'),
                    { hoursValue: v },
                  );
                },
              },
            },
          },
        };
      },
    },

    watch: {
      teams(newTeams) {
        this.destroyCharts();
        this.destroyMasonry();

        this.$nextTick(() => {
          this.initMasonry();
          newTeams.forEach(this.initSmallChart);
        });
      },
    },

    created() {
      this.loadTeamsPerformance();
    },

    beforeDestroy() {
      this.destroyCharts();
      this.destroyMasonry();
    },

    methods: {
      ...mapWaitingActions('project/metrics', {
        loadTeamsPerformance: 'loading trends',
      }),

      initMasonry() {
        this.masonry = new Colcade('.masonry-container', { // eslint-disable-line
          columns: '.masonry-col',
          items: '.masonry-item',
        });
      },

      destroyMasonry() {
        if (!this.masonry) return;
        this.masonry.destroy();
        this.masonry = null;
      },

      initSmallChart(team) {
        // Prevents duplicate initialization, just in case
        const chartInitialized = this.charts.some(c => c.config.options.teamId === team.teamId);
        if (chartInitialized) return;

        const options = {
          ...this.chartOptions,
          teamId: team.teamId,
        };

        const keys = this.datasetOptions.map(ds => ds.id);
        const datasets = team.taktsData.reduce((acc, taktData) => {
          keys.forEach((key) => {
            const {
              label,
              chartOptions,
            } = this.datasetOptions.find(dso => dso.id === key);

            if (!acc[key]) {
              acc[key] = {
                datasetKey: key,
                data: [],
                hidden: false,
                label,
                ...chartOptions,
              };

              this.setVisibility(acc[key]);
            }

            acc[key].data.push(taktData[key] / 60);
          });

          return acc;
        }, {});

        const chart = new Chart(this.$refs[`team-chart-${team.teamId}`], { // eslint-disable-line
          type: 'line',
          data: {
            labels: this.meta.takts,
            datasets: Object.values(datasets),
          },
          options,
        });

        this.charts.push(chart);
      },

      onDatasetChange() {
        this.charts.forEach((chart) => {
          chart.data.datasets.forEach(this.setVisibility);
          chart.update();
        });
      },

      setVisibility(ds) {
        const showAll = this.selectedDatasetsIds.length <= 0;

        if (showAll) {
          ds.hidden = false; // eslint-disable-line
        } else {
          ds.hidden = !this.selectedDatasetsIds.includes(ds.datasetKey); // eslint-disable-line
        }
      },

      destroyCharts() {
        this.charts.forEach(x => x.destroy());
        this.charts = [];
      },

      destroyChart(chart) {
        const i = this.charts.findIndex(c => c.id === chart.id);
        this.charts.splice(i, 1);

        chart.destroy();
      },

      goBack() {
        this.$router.push(`/projektit/${this.$projectId}`);
      },
    },
  };
</script>

<style lang="scss">
  .v-skeleton-loader__chart {
    width: 400px;
    height: 200px;
    margin: 44px;
  }
</style>

<style lang="scss" scoped>
.masonry-container {
  display: flex;
}

.masonry-col {
  flex: 1;
}

/* 1 column by default, hide columns 2 & 3 */
.masonry-col:nth-child(2),
.masonry-col:nth-child(3) {
  display: none;
}

.masonry-item {
  margin: 0 24px 24px 0;
}

/*

  If there are odd number of masonry-items, the last one may be rendered
  on the bottom right side. It could be fixed by setting the previous
  items' height 1 pixel smaller to allow masonry to build up correctly.

  .masonry-col:first-of-type .masonry-item {
    height: <1 px smaller than the last one>;
  }

*/

/* 2 columns at medium size */
@media (min-width: 1280px) {
  /* show column 2 */
  .masonry-col:nth-child(2) {
    display: block;
  }
}

/* 3 columns at large size */
@media (min-width: 1920px) {
  /* show column 3 */
  .masonry-col:nth-child(3) {
    display: block;
  }
}
</style>
